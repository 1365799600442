import React, { useState, useEffect } from 'react';
import PrimaryButton from '../../hooks/PrimaryButton';
import SecondaryButton from '../../hooks/SecondaryButton';
import { SquaresPlusIcon } from 'heroicons-v2/24/solid';
import Modal from '../../hooks/Modal';
import FileInputLabel from '../../hooks/FileInputLabel';
import Alert from '../../hooks/Alert';
import SuccessfullModalContent from '../../hooks/SuccessfullModalContent';
import PropTypes from 'prop-types';
import { updateProfilePhoto, updateUser } from '../../../apiClient/operations/accountsOperations';
import Loader from '../../hooks/Loader';
import useS3 from '../../hooks/useS3';
import useUser from '../../hooks/useUser';

function ProfilePhoto({ user }) {

    const [openModal, setOpenModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [preview, setPreview] = useState({ url: '', width: '', height: '' });
    const [updateSuccessfull, setUpdateSuccessfull] = useState(false);
    const [generalError, setGeneralError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [rfc, setRfc] = useState('');
    const [imageDimensions, setImageDimensions] = useState({ width: 80, height: 80 });
    const { mutateUser } = useUser();
    const { uploadPublicFile, renameFile, fileType } = useS3();


    useEffect(() => {
        if (!user) return

        setRfc(user?.tax_id);
        if (user?.image !== undefined) {
            let img = new Image();
            img.src = user.image;
            img.onload = function () {
                setImageDimensions({ width: img.width, height: img.height })
            }
        }
    }, [user]);

    useEffect(() => {
        return () => {
            setTimeout(() => {
                setPreview({ url: '', width: '', height: '' })
                setError(null);
                setUpdateSuccessfull(false);
            }, 1000);
        }
    }, [openModal]);

    const handleUpdatePhoto = async () => {
        setLoader(true);
        try {
            const baseKey = `account-profile/${rfc}/profile-photo`;
            const image = renameFile(files[0], `${new Date().getTime()}.${fileType(files[0].name)}`);
            const imageObject = await uploadPublicFile(image, `${baseKey}/${image.name}`);

            const bodyObject = {
                bucket: imageObject.bucket,
                object_name: imageObject.key
            }
            await updateProfilePhoto(bodyObject);
            const newImageUrl = `https://s3.amazonaws.com/${bodyObject.bucket}/${bodyObject.object_name}`;
            let accounts;
            if (user?.accounts.length > 1) {
                accounts = user?.accounts?.map(account => {
                    if (account.id == user?.id) {
                        return {
                            ...account,
                            image: newImageUrl
                        }
                    }
                    return account
                })
            }
            await mutateUser(updateUser({
                image: newImageUrl,
                ...accounts && { accounts: accounts }
            }));

            setLoader(false);
            setUpdateSuccessfull(true);
        } catch (e) {
            setLoader(false);
            console.log(e);
            setError(e.messageToUser || 'Error al procesar tu solicitud');
        }
    }

    return (
        <>
            <Loader show={loader} title='Actualizando foto de perfil...' />

            <div className={`${generalError !== null ? 'my-6' : 'mt-8 lg:mt-4'} top-20 sticky z-[99]`}>
                <Alert type="Error" show={generalError != null} title={generalError} onClose={() => setGeneralError(null)} />
            </div>

            <div className='w-full border border-gray-300 p-4 bg-white rounded-md flex flex-col lg:flex-row items-start lg:items-center justify-between mt-5'>
                <div className='flex gap-4 items-center'>
                    {!user?.image ?
                        <div className='min-w-[4rem] min-h-[4rem] max-w-[4rem] max-h-[4rem] bg-gray-500 rounded-full text-white text-2xl flex justify-center items-center overflow-hidden'>
                            {user?.legal_type === 'personal' ? `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}` : `${user?.name?.charAt(0)}`}
                        </div>
                        :
                        <div className={`${(Number(imageDimensions.width) > Number(imageDimensions.height) && Number(imageDimensions.width < 980)) ? 'bg-contain' : 'bg-cover'} min-w-[4rem] min-h-[4rem] bg-center bg-no-repeat rounded-full bg-blue-sidebar`} style={{ backgroundImage: `url("${user?.image}")` }} />
                    }
                    <div className='flex flex-col'>
                        <h3 className='text-gray-900 font-bold text-2xl'>
                            {user?.name || ''}
                        </h3>
                        <p className='text-gray-500 font-medium text-sm'>
                            {user?.tax_id || ''}
                        </p>
                    </div>
                </div>
                <div className='w-full lg:w-auto mt-4 lg:ml-0 lg:mt-0'>
                    <SecondaryButton isFullWidth onClick={() => setOpenModal(true)}>
                        <SquaresPlusIcon className='w-5 h-5 mr-4' /> {user?.image ? 'Actualizar foto' : 'Subir foto'}
                    </SecondaryButton>
                </div>
            </div>

            <Modal open={openModal} setOpen={setOpenModal} className='min-w-full lg:min-w-[30rem]'>
                {updateSuccessfull ?
                    <SuccessfullModalContent title='Foto de perfil actualizada correctamente' setOpenModal={setOpenModal} />
                    :
                    <div className='w-full p-4'>
                        {error !== null &&
                            <div className="w-full  mb-4 sticky top-0">
                                <Alert type="Error" show={error != null} title={error} onClose={() => setError(null)} />
                            </div>
                        }
                        <div className='text-center text-lg text-gray-500 font-medium'>
                            Sube la imagen para tu perfil fiscal:
                            <p className='text-2xl mt-4 text-gray-900'>
                                {user?.name}
                            </p>
                        </div>
                        <div className='mx-auto mt-4 min-w-[11.5rem] min-h-[11.5rem] w-[11.5rem] h-[11.5rem] rounded-full relative border border-gray-300 bg-gray-500 flex justify-center items-center text-white'>
                            {preview.url ?
                                <div className={`${(Number(preview.width) > Number(preview.height) && Number(preview.width < 980)) ? 'bg-contain' : 'bg-cover'} w-[11.5rem] h-[11.5rem] bg-blue-sidebar rounded-full bg-center bg-no-repeat p-1`} style={{ backgroundImage: `url("${preview.url || 'https://cdn.fixat.mx/intranet/logo-principal-fixat.png'}")` }} />
                                : user?.image ?
                                    <div className={`${(Number(imageDimensions.width) > Number(imageDimensions.height) && Number(imageDimensions.width < 980)) ? 'bg-contain' : 'bg-cover'} w-[11.5rem] h-[11.5rem] bg-blue-sidebar rounded-full bg-center bg-no-repeat p-1`} style={{ backgroundImage: `url("${user?.image}")` }} />
                                    :
                                    <div className='text-4xl font-medium'>
                                        {user?.legal_type === 'personal' ? `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}` : `${user?.name?.charAt(0)}`}
                                    </div>
                            }
                        </div>
                        <div className='w-full flex justify-center mt-2 mb-10'>
                            <FileInputLabel
                                id='logo'
                                accept={['.png', '.jpg', '.jpeg']}
                                limitSize={1}
                                limitFiles={1}
                                setFiles={setFiles}
                                isLogo={true}
                                setError={setError}
                                setPreview={setPreview}
                                dimensions={[3180, 3180]}
                            />
                        </div>
                        <PrimaryButton isFullWidth disabled={preview.url == '' || error !== null} onClick={handleUpdatePhoto}>
                            Actualizar foto de perfil
                        </PrimaryButton>
                    </div>
                }
            </Modal>
        </>
    )
}

ProfilePhoto.propTypes = {
    user: PropTypes.object
}

export default ProfilePhoto