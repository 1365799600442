import React from 'react'
import Image from 'next/image'

function Header({ name }) {
    return (
        <div className='w-full mt-0 lg:mt-16 text-center block lg:flex justify-start'>
            <div>
                <div className='w-full hidden lg:flex'>
                    <Image
                        src="https://cdn.fixat.mx/assets/taxpayers/fixat-logo-azul-v2.svg"
                        alt="Logo Fixat"
                        width={139}
                        height={32}
                    />
                </div>
                <div className='w-full bg-blue-sky flex justify-start px-3 py-2 lg:hidden'>
                    <Image
                        src="https://cdn.fixat.mx/assets/taxpayers/logo-principal-fixat-blanco.png"
                        alt="Logo Fixat"
                        width={169}
                        height={44}
                    />
                </div>
                <div className='mt-4 text-blue-sidebar text-2xl pt-4 px-4 lg:p-0 max-w-full text-center lg:text-left mb-5'>
                    {name ? (
                        `Hola ${name}, te damos la bienvenida a nuestro portal`
                    ) : (
                        'Te damos la bienvenida a nuestro portal'
                    )}
                </div>
            </div>
        </div>
    )
}

export default Header