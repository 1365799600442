import apiClient from '../apiClient';

export const getTaxStatus = async () => {
    const res = await apiClient.get(`/api/fetch-cache/fiscal/taxpayer/tax/status/get`);
    return res.data
}

export const getTaxCompliance = async () => {
    const res = await apiClient.get(`/api/fetch-cache/fiscal/taxpayer/tax/compliance/get`);
    return res.data
}