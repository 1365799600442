import React, { useState, useEffect, cloneElement } from 'react';
import Link from 'next/link';
import { CurrencyDollarIcon } from 'heroicons-v2/24/solid';
import ProfilePhoto from '../components/profile/GeneralInfo/ProfilePhoto';
import CustomAdvice from '../components/hooks/CustomAdvice';
import Tabs from '../components/hooks/Tabs';

function FixatProfileLayout({ children, user }) {

    const [alert, setAlert] = useState(null);
    const [tabs, setTabs] = useState([
        { id: 1, name: 'Balance', current: true, route: '/perfil-fixat/balance-pagos' },
        { id: 2, name: 'Mi plan FIXAT', current: true, route: '/perfil-fixat/mi-plan' },
        { id: 3, name: 'Mi perfil', current: true, route: '/perfil-fixat/informacion' }
    ]);

    useEffect(() => {
        // if (user?.dueAmount > 0) {
        if (user?.status == 'suspended' || user?.status == 'defaulter' ) {
            setAlert({
                'type': 'Warning',
                'title': (
                    <span>Tu cuenta presenta adeudos, evita la suspensión.&nbsp;
                        <Link href='/perfil-fixat/balance-pagos#pago'>
                            <a className='underline'>
                                Realiza tu pago.
                            </a>
                        </Link>
                    </span>
                )
            })
        }
    // }, [user?.dueAmount])
}, [user?.status])

    return (
        <>
            <div className='w-full px-0 py-4 lg:p-6 bg-gray-100 min-h-[calc(100dvh-4rem)] pb-12'>
                {alert && (
                    <div className='z-[30] mb-4'>
                        <CustomAdvice
                            type={alert?.type}
                            title={alert?.title}
                            message={alert?.message} />
                    </div>
                )}
                <div className='w-full px-4 lg:px-0'>
                    <h1 className='text-gray-900 font-bold text-3xl'>
                        Perfil FIXAT
                    </h1>
                    <div className='flex gap-2 items-center'>
                        <CurrencyDollarIcon className='w-5 h-5 text-gray-500' />
                        <span className='text-gray-500 text-gray-400 text-sm'>
                            Peso mexicano - Montos incluyen IVA
                        </span>
                    </div>
                    <ProfilePhoto user={user} />
                </div>
                <div className="mt-5">
                    <Tabs
                        items={tabs}
                        setItems={setTabs}
                    />
                </div>
                <div className='w-full px-4 lg:px-0'>
                    {React.Children.map(children,
                        child => (
                            cloneElement(child, { user, setGeneralAlert: setAlert })
                        )
                    )}
                </div>
            </div>
        </>
    )
}

export default FixatProfileLayout