import '../styles/globals.css';
import HomeLayout from '../layouts/HomeLayout';
import LoginLayout from '../layouts/LoginLayout';
import ProfileLayout from '../layouts/ProfileLayout';
import FixatProfileLayout from '../layouts/FixatProfileLayout';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import IncomesExcomesLayout from '../layouts/IncomesExcomesLayout';
import TaxSituationLayout from '../layouts/TaxSituationLayout';
import InvoicingSettingsLayout from '../layouts/InvoicingSettingsLayout';

function MyApp({ Component, pageProps, router }) {

  const loginPages = [
    '/admin/inicia-sesion',
    '/inicia-sesion',
    '/olvidaste-tu-contrasena',
    '/modificar-contrasena',
    '/verificar-identidad',
    '/crear-contrasena',
    '/contribuyente-no-encontrado',
  ]

  const profilePages = [
    '/perfil/resumen',
    '/perfil/informacion-general',
    '/perfil/accesos-sat',
    '/perfil/contrato',
    '/perfil/pagos',
    '/perfil/facturas'
  ]

  const fixatProfilePages = [
    '/perfil-fixat/balance-pagos',
    '/perfil-fixat/mi-plan',
    '/perfil-fixat/informacion'
  ]

  const taxSituationPages = [
    '/situacion-fiscal/constancia',
    '/situacion-fiscal/cumplimiento',
    '/situacion-fiscal/accesos-sat'
  ]

  const incomesExpensesPages = [
    '/declaraciones/historico',
    '/declaraciones/ingresos',
    '/declaraciones/egresos',
    '/declaraciones/egresos-extranjero',
  ]

  const invoicingSettingsPages = [
    '/facturacion/ajustes/informacion',
    '/facturacion/ajustes/certificados'
  ]

  const outsidePages = [
    '/404',
    '/503',
    '/500',
    '/verificar-correo'
  ]

  const pathname = router.pathname;

  const getLayout = () => {
    if (outsidePages.includes(pathname)) {
      return <Component {...pageProps} />
    }

    if (loginPages.includes(pathname)) {
      return <LoginLayout>
        <Component {...pageProps} />
      </LoginLayout>
    }

    if (profilePages.includes(pathname)) {
      return <HomeLayout>
        <ProfileLayout>
          <Component {...pageProps} />
        </ProfileLayout>
      </HomeLayout>
    }

    if (fixatProfilePages.includes(pathname)) {
      return <HomeLayout>
        <FixatProfileLayout>
          <Component {...pageProps} />
        </FixatProfileLayout>
      </HomeLayout>
    }

    if (taxSituationPages.includes(pathname)) {
      return <HomeLayout>
        <TaxSituationLayout>
          <Component {...pageProps} />
        </TaxSituationLayout>
      </HomeLayout>
    }

    if (incomesExpensesPages.includes(pathname)) {
      return <HomeLayout>
        <IncomesExcomesLayout>
          <Component {...pageProps} />
        </IncomesExcomesLayout>
      </HomeLayout>
    }

    if (invoicingSettingsPages.includes(pathname)) {
      return <HomeLayout>
        <InvoicingSettingsLayout>
          <Component {...pageProps} />
        </InvoicingSettingsLayout>
      </HomeLayout>
    }

    return <HomeLayout>
      <Component {...pageProps} />
    </HomeLayout>
  }

  return (
    <>
      <DefaultSeo
        title="FIXAT | Portal del Contribuyente"
        noindex={false}
        nofollow={false}
      />
      <Head>
        <meta name="description" content="FIXAT | Portal del Contribuyente" />
        <meta name='theme-color' content='#146DBF'></meta>
        <link rel="icon" href="/icon.png" />
      </Head>
      {getLayout()}
    </>
  )
}

export default MyApp
