import useSWR from 'swr';
import { useEffect } from 'react';
import Router from 'next/router';

const fetcher = async (url) => fetch(url).then(res => res.json());

export default function useUser() {
    const { data: user, mutate: mutateUser } = useSWR("/api/accounts/user", fetcher);

    useEffect(() => {
        
        if (!user || user?.isLoggedIn == undefined) return;

        if (!user?.isLoggedIn) {
            const path = Router.asPath;
            if (path == '/inicia-sesion' || path == '/admin/inicia-sesion') return;
            if (Object.keys(Router.components).length == 2 && path != '/inicia-sesion'){
                localStorage.setItem("previousPath", path);
            }
            Router.push('/inicia-sesion');
        }  

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return { user, mutateUser };
}