import apiClient from '../apiClient';

export const createPresignedPost = async (fileName) => {
    const response = await apiClient.get(`/api/s3-upload?file=${fileName}`);
    return response.data;
}

export const createPresignedPublicPost = async (fileName) => {
    const response = await apiClient.get(`/api/s3-public?file=${fileName}`);
    return response.data;
}

export const uploadToS3 = async (url, formData) => {
    return fetch(url, {
        method: 'POST',
        body: formData,
    })
    .then(response => { return response });
}

export const download = async (bucket, key) => {
    const response = await fetch('/api/s3/download', {
        method: 'POST',
        body: JSON.stringify({
            bucket: bucket,
            key: key
        })
    })
    if (!response.ok) return 'Hubo un error al descargar el archivo';
    return await response.blob();
}

export const uploadCSD = async (formData) => {
    const response = await apiClient.post('/api/s3/upload-csd', formData);
    return response.data;
}

export const upload = async (url, formData) => {
    return fetch(url, {
        method: 'POST',
        body: formData,
    })
    .then(response => { return response });
}