import React, { useState, Children, cloneElement } from 'react'
import Image from 'next/image'
import Header from '../components/login/Header'
import { useRouter } from 'next/router'

function LoginLayout({ children }) {

    const router = useRouter();
    const [lead, setLead] = useState(null);

    return (
        <>
            <div className="w-full max-w-screen-xl mx-auto px-0 lg:px-6 flex items-start justify-between h-full min-h-[calc(100vh-10rem)] lg:min-h-[32rem] without_scrollbar">
                <div className='w-full lg:w-1/2 mb-24'>
                    <Header name={lead?.first_name}/>
                    <div className="w-full flex flex-col justify-start z-30 px-4 lg:px-0">
                        <div className="w-full max-w-screen-sm mx-auto lg:max-w-full">
                            {Children.map(children,
                                child => (
                                    cloneElement(child, { setLead })
                                )
                            )}
                        </div>
                        <div className='block lg:flex items-center gap-1 mt-10 lg:mt-6'>
                            <div className='hidden lg:block w-2/3 text-gray-500 text-base mt-2'>
                                Con la <span className='font-bold text-gray-900'>tecnología SSL</span> tus datos personales están siempre protegidos.
                            </div>
                            <div className='w-full lg:w-1/3 flex justify-center gap-1 items-center lg:justify-end'>
                                <div className='relative w-[5rem] h-[60px] mt-1 lg:mt-0'>
                                    <Image
                                        src='https://cdn.fixat.mx/assets/invoicing/seguridad_encriptada.png'
                                        alt='Encrypted logotipo'
                                        layout='fill'
                                        objectFit='contain'
                                    />
                                </div>
                            </div>
                            <div className='block lg:hidden w-full text-gray-500 text-base mt-2 text-center'>
                                Con la <span className='font-bold text-gray-900'>tecnología SSL</span> tus datos personales están siempre protegidos.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='hidden lg:flex w-1/2 h-full flex-col justify-end items-end'>
                    <div className={`${router.pathname == '/crear-contrasena' ? 'hidden' : ''} z-1 absolute top-32`}>
                        <Image
                            width='421px'
                            height='380px'
                            src="https://cdn.fixat.mx/assets/invoicing/gray-path.png"
                            alt="Fondo punteado gris"
                            priority
                        />
                    </div>
                    <div className='w-full pl-12'>
                        <div className='w-full fixed bottom-10 z-[11]'>
                            <div className='z-[5] absolute bottom-0 pl-11'>
                                <Image
                                    src="https://cdn.fixat.mx/assets/taxpayers/fondo-ciudad.png"
                                    alt="Ciudad Fixat"
                                    width={530}
                                    height={320}
                                />
                            </div>
                            <div className='z-[20] absolute bottom-0 -mb-4 -ml-4'>
                                <Image
                                    width='243.9px'
                                    height='304.9px'
                                    src="https://cdn.fixat.mx/assets/taxpayers/Fixi-inicia-sesion.png"
                                    alt="Fixi inicia sesión"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full h-12 bg-blue-sidebar hidden lg:block fixed bottom-0 z-10' />
        </>
    )
}

export default LoginLayout