import apiClient from '../apiClient';

export const searchMessages = async (page = 1, limit = 10, search, filters, order = -1, field = ' created',) => {
    let queryParams = `page=${page}&limit=${limit}&order=${order}&field=${field}`;
    if (search || filters) {
        queryParams += `&q=${search ? ('wildCard~' + search) : ''}${filters ? ((search ? ' AND ' : '') + filters) : ''}`;
    }
    const response = await apiClient.get(`/api/fetch/taxpayer-mailbox/search?${queryParams}`);
    return response.data;
}

export const getMessages = async (page, limit) => {
    const queryParams = `${page && 'page=' + page}${limit && '&limit=' + limit}`;
    const response = await apiClient.get(`/api/fetch/taxpayer-mailbox/ /?${queryParams}`);
    return response.data;
}

export const getUnseenMessagesCount = async () => {
    const response = await apiClient.get(`/api/fetch/taxpayer-mailbox/unseen/count`);
    return response.data;
}

export const updateMessageStatus = async (messageId, status) => {
    const response = await apiClient.put(`/api/fetch/taxpayer-mailbox/${messageId}/status`, {
        status
    });
    return response.data;
}

export const getNotifications = async (page, limit, status) => {
    console.lo
    const response = await apiClient.get(`/api/fetch/taxpayer-mailbox/notifications?${page && 'page=' + page}${limit && '&limit=' + limit}${status && '&status=' + status}`);
    return response.data;
}

export const getUnseenNotificationsCount = async () => {
    const response = await apiClient.get(`/api/fetch/taxpayer-mailbox/notifications/unseen/count`);
    return response.data;
}

export const updateNotificationStatus = async (notificationId, status) => {
    const response = await apiClient.put(`/api/fetch/taxpayer-mailbox/notifications/${notificationId}/status`, {
        status
    });
    return response.data;
}

export const registerToken = async (token) => {
    const response = await apiClient.post(`/api/accounts/notifications/token`, {
        "fcm_token": token
    });
    return response.data;
}