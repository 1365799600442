import apiClient from '../apiClient';

export const getOverview = async () => {
    const res = await apiClient.get(`/api/fetch-cache/balance-taxpayer/taxpayer/overview`);
    return res.data
}

export const deleteOverviewCache = async () => {
    const res = await apiClient.delete(`/api/fetch-cache/balance-taxpayer/taxpayer/overview`);
    return res.data;
}


export const getCharges = async (sort, q, page=1, limit = 10) => {
    const res = await apiClient.get(`/api/fetch-cache/balance-taxpayer/taxpayer?sort=desc&${q}limit=${limit}&page=${page}${sort}`);
    return res.data
}

export const getOrder = async (orderId) => {
    const res = await apiClient.get(`/api/fetch/orders/${orderId}`);
    return res.data
}

export const paymentCard = async (orderId, request) => {
    const res = await apiClient.post(`/api/fetch/orders/${orderId}/taxpayer/payment/card`, request);
    return res.data;
}

export const createPaypalOrder = async (orderId, items) => {
    const res = await apiClient.post(`/api/fetch/orders/${orderId}/taxpayer/payment/paypal`, {
        ...(items && {
            "items": items
        })
    });
    return res.data;
}

export const createOxxoOrder = async (orderId, items) => {
    const res = await apiClient.post(`/api/fetch/orders/${orderId}/taxpayer/payment/oxxo`, {
        ...(items && {
            "items": items
        })
    });
    return res.data;
}

export const createSpeiOrder = async (orderId, items) => {
    const res = await apiClient.post(`/api/fetch/orders/${orderId}/taxpayer/payment/spei`, {
        ...(items && {
            "items": items
        })
    });
    return res.data;
}