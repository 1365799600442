import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function Button({ isFullWidth, isTransparent, disabled, children, size, ...rest }) {

    const styles = useMemo(() => {
        let styles = isTransparent ? 'bg-transparent hover:bg-hover-orange text-white hover:text-white border-orange' : 'bg-orange hover:bg-hover-orange text-white border-transparent';
        if (disabled) {
            styles = isTransparent ? 'border-gray-300 text-gray-300' : 'bg-gray-50 border-gray-300 text-gray-500'
        }
        styles += ` text-${size}`;
        return styles;
    }, [isTransparent, disabled, size])

    return (
        <button
            disabled={disabled}
            {...rest}
            className={`${isFullWidth && 'w-full'} ${styles} transition-all inline-flex items-center justify-center px-4 py-2 border shadow-sm font-medium rounded-md`}>
            {children}
        </button>
    )
}

Button.propTypes = {
    isFullWidth: PropTypes.bool,
    isTransparent: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.array
    ]),
    size: PropTypes.string
}

Button.defaultProps = {
    isFullWidth: false,
    isTransparent: false,
    disabled: false,
    size: 'base'
}

export default Button;