import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from 'heroicons-v2/24/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function SwitchProfile({ accounts, selected, setSelected }) {

    return (
        <div>
            <p className='text-white text-xs font-medium mb-1'>
                Perfil seleccionado
            </p>
            <div className='w-full mb-6'>
                <Listbox value={selected} onChange={setSelected}>
                {({ open }) => (
                    <>
                    <div className="relative cursor-pointer text-sm">
                        <Listbox.Button className="relative w-full rounded-md bg-white py-1.5 pl-1 pr-7 text-left border border-gray-200 text-gray-900 shadow-sm focus:outline-none sm:leading-6">
                        <span className="flex items-center">
                            {selected?.image === null || selected?.image === undefined ?
                                <div className='min-w-[2rem] min-h-[2rem] max-w-[2rem] max-h-[2rem] bg-gray-500 rounded-full text-white text-sm flex justify-center items-center overflow-hidden'>
                                    { selected?.legal_type === 'personal' ? `${selected?.first_name.charAt(0)}${selected?.last_name.charAt(0)}` : `${selected?.name.charAt(0)}` }
                                </div>
                                :
                                <div className={`min-w-[2rem] min-h-[2rem] ${selected?.image === undefined || selected?.image === null ? 'bg-gray-600 text-white' : `${ (Number(selected?.dimensions?.width) > Number(selected?.dimensions?.height) && Number(selected?.dimensions?.width < 980)) ? 'bg-contain' : 'bg-cover'} text-transparent bg-blue-sky bg-center bg-no-repeat rounded-full`}`} style={{backgroundImage: `url(${selected?.image})`}}/>
                            }
                            <span className="ml-2 block truncate">{selected?.name}</span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex flex-col items-center pr-2">
                            <ChevronUpIcon className="min-h-[1.25rem] min-w-[1.25rem] text-gray-400 mt-1" aria-hidden="true" />
                            <ChevronDownIcon className="min-h-[1.25rem] min-w-[1.25rem] text-gray-400 -mt-1.5" aria-hidden="true" />
                        </span>
                        </Listbox.Button>

                        <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {accounts.filter(p => p?.id !== selected?.id).map((person) => (
                            <Listbox.Option
                                key={person.id}
                                className={({ active }) =>
                                classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-900',
                                    'relative select-none py-1 pl-1 pr-2'
                                )
                                }
                                value={person}
                            >
                                {({ selected, active }) => (
                                <>
                                    <div className="flex items-center text-sm">
                                    {person?.image === null || person?.image === undefined ?
                                        <div className='min-w-[2rem] min-h-[2rem] max-w-[2rem] max-h-[2rem] bg-gray-500 rounded-full text-white text-sm flex justify-center items-center overflow-hidden'>
                                            { person?.legal_type === 'personal' ? `${person?.first_name.charAt(0)}${person?.last_name.charAt(0)}` : `${person?.name.charAt(0)}` }
                                        </div>
                                        :
                                        <div className={`min-w-[2rem] min-h-[2rem] ${person?.image === undefined || person?.image === null ? 'bg-gray-600 text-white' : `${ (Number(person?.dimensions?.width) > Number(person?.dimensions?.height) && Number(person?.dimensions?.width < 980)) ? 'bg-contain' : 'bg-cover'} text-transparent bg-blue-sky bg-center bg-no-repeat rounded-full`}` } style={{backgroundImage: `url(${person?.image})`}}/>
                                    }
                                    <span
                                        className={classNames(selected ? 'font-medium' : 'font-normal', 'ml-2 block truncate text-sm')}
                                    >
                                        {person?.name}
                                    </span>
                                    </div>

                                    {selected ? (
                                    <span
                                        className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    ) : null}
                                </>
                                )}
                            </Listbox.Option>
                            ))}
                        </Listbox.Options>
                        </Transition>
                    </div>
                    </>
                )}
                </Listbox>
            </div>
        </div>
    )
}

export default SwitchProfile