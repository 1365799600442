export const formatCurrency = (
    amount,
    currency = 'MXN'
) => {
    if (isNaN(amount)) return '';
    const maximumFractionDigits = 2;
    const minimumFractionDigits = amount % 1 == 0 ? 0 : 2;

    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: maximumFractionDigits, 
        minimumFractionDigits: minimumFractionDigits,
    });
    const price = formatter.format(amount);
    
    if (currency == 'MXN') return price.replace(/^(.{2})(.+)/, '$2$1').replace("MX", " MXN");

    return `${price} ${currency}`;
}

export const formatPeso = (amount) => {
    const minimumFractionDigits = amount % 1 == 0 ? 0 : 2;
    const maximumFractionDigits = 2;
    const formatterPeso = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
    });
    return formatterPeso.format(amount)
};

export const luhnAlgorithm = (value) => {
    if (/[^0-9-\s]+/.test(value)) return false;
    let nCheck = 0, bEven = false;
    value = value.replace(/\D/g, "");
    for (let n = value.length - 1; n >= 0; n--) {
        let cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);
        if (bEven && (nDigit *= 2) > 9) nDigit -= 9; 
        nCheck +=  nDigit; 
        bEven = !bEven;
    }
    return (nCheck % 10) == 0;
}