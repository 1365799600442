import moment from 'moment-timezone';
import 'moment/locale/es';
moment().local('es');

export const dateFormatter = (date, format) => moment(date).tz(moment.tz.guess()).format(format).replace(".", "");

export const unixToDate = (unixDate) => moment(unixDate);

export const stringToDate = (stringDate, formatDate) => moment(stringDate, formatDate);

const months = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre'
}

export const getMonthName = id => months[parseInt(id)] || '';

export const getMonthDifference = (startDate, endDate) => endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());

export const monthCleaner = month => month < 10 ? `0${month}` : month.toString();


const monthsL = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic']
const daysL = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']

export const locale = {
    localize: {
        day: n => daysL[n],
        month: n => monthsL[n]
    },
    formatLong: {
        date: () => 'dd/MM/yyyy'
    }
}