import React from 'react';
import PropTypes from 'prop-types';

function SkeletonLoader({ height }) {
    return (
        <div className={`animate-pulse bg-gray-300 w-full ${height} rounded-full`}/>
    )
}

SkeletonLoader.propTypes = {
    height: PropTypes.string
}

SkeletonLoader.defaultProps = {
    height: 'h-3'
}

export default SkeletonLoader;