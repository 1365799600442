import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function FileInputLabel({
    label,
    description,
    info,
    id,
    accept,
    limitSize,
    limitFiles,
    setFiles,
    setError,
    isLogo,
    dimensions,
    setPreview,
    register,
    needed,
    ...rest
}) {

    const [selectedFiles, setSelectedFiles] = useState([]);

    const selectFile = e => {
        const files = e.target.files;
        handleFiles(files);
    }

    const verifyResolution = (file, callback) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = function () {
            let width = img.width,
                height = img.height;
            const size = { width: width, height: height }
            callback(size)
        };
    }
    //create url to get a preview image
    const showPreview = (file, w, h) => {
        let objectUrl = window.URL.createObjectURL(file[0]);
        const previewImage = {
            url: objectUrl,
            width: `${w}`,
            height: `${h}`,
            name: file[0].name
        }
        setPreview(previewImage);
    }

    //handle selected files
    const handleFiles = files => {
        setError(null);
        setPreview({ url: '', width: '', height: '' });
        const fileArray = [...files]
        if (fileArray.length > limitFiles) {
            setError(`Número de archivos mayor a ${limitFiles}`);
        } else {
            let validFiles = []
            let error = null
            fileArray.forEach(file => {
                if (!isValidFile(file.name, accept)) {
                    error = fileArray.length == 1 ? 'Archivo incompatible' : 'Uno de tus archivos es incompatible';
                } else if (bytesToMegaBytes(file.size) > limitSize) {
                    error = fileArray.length == 1 ? `El tamaño máximo permitido es de ${limitSize} MB` : `Uno de tus archivos excede el tamaño máximo permitido: ${limitSize} MB`;
                } else if (isLogo) {
                    verifyResolution(file, (res) => {
                        if (res.width < dimensions[0] && res.height < dimensions[1]) {
                            setSelectedFiles([file]);
                            showPreview([file], res.width, res.height);
                        } else {
                            setError(`El logo debe tener una dimensión máxima de ${dimensions[0]}x${dimensions[1]}`);
                        }
                    });
                } else {
                    validFiles.push(file);
                }
            });
            setSelectedFiles(validFiles);
            setError(error);
        }
    }

    //validate file extension
    const isValidFile = (fileName, extensions) => {
        if (extensions.length == 0) return true;
        const pattern = `.(${extensions.map(ext => ext.toLowerCase()).join('|')})$`;
        const regex = new RegExp(pattern, 'i');
        return regex.test(fileName);
    }

    const bytesToMegaBytes = bytes => (bytes / (1024 ** 2)).toFixed(3);

    //return files when selected files change
    useEffect(() => {
        if (selectedFiles.length > 0 && setFiles) setFiles(selectedFiles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFiles]);

    return (
        <>
            {/*<div className='flex justify-center relative'>
                <button className="text-blue-sidebar font-bold py-2 px-4 flex items-center absolute justify-center cursor-pointer z-[10]">
                    <span className='cursor-pointer'>Cargar imagen o logo</span>
                </button>
                <input
                    type="file"
                    className="cursor-pointer absolute opacity-0 overflow-hidden z-[-1] w-0 h-0 py-2"
                    onChange={selectFile}
                    multiple={limitFiles > 1}
                    accept={accept?.join(', ')}
                    {...register}
                    {...rest} />
                </div>*/}
            <input
                type="file"
                name='image_profile'
                id='image_profile'
                className="absolute opacity-0 overflow-hidden z-[-1] w-0 h-0 py-2"
                onChange={selectFile}
                multiple={limitFiles > 1}
                accept={accept?.join(', ')}
                {...register}
                {...rest} />
            <label htmlFor="image_profile" className='text-blue-sidebar font-bold cursor-pointer px-1 mt-1'>Elegir imagen o logo</label>
        </>
        
    )
}

FileInputLabel.propTypes = {
    label: PropTypes.string,
    description: PropTypes.string,
    info: PropTypes.string,
    id: PropTypes.string,
    accept: PropTypes.arrayOf(PropTypes.string),
    limitSize: PropTypes.number,
    limitFiles: PropTypes.number,
    setFiles: PropTypes.func,
    error: PropTypes.string,
    isLogo: PropTypes.bool,
    dimensions: PropTypes.arrayOf(PropTypes.number),
    setPreview: PropTypes.func
}

FileInputLabel.defaultProps = {
    description: 'Sube un archivo',
    accept: [],
    //limit size in MB
    limitSize: 1,
    limitFiles: 1,
    isLogo: false,
    dimensions: [0, 0]
}

export default FileInputLabel;