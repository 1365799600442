import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Tabs({ items, setItems }) {

    const router = useRouter();

    const handleChangeTab = route => {
        const newTabs = items.map(tab => ({
            ...tab,
            current: tab.route === route
        }));
        setItems(newTabs);
        const currentTab = document.getElementById(newTabs.find(tab => tab.current).id);
        currentTab?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "center" });
    }

    useEffect(() => {
        handleChangeTab(router.pathname);
    }, [router.pathname]);

    return (
        <>
            <div className="border-b border-gray-300">
                <div className={`overflow-scroll without_scrollbar px-4 snap-x flex space-x-0 lg:space-x-8`} aria-label="Tabs">
                    {items.map((tab) => (
                        <Link
                            id={tab.id}
                            key={tab.id}
                            onClick={() => handleChangeTab(tab.route)}
                            href={tab.route}
                            legacyBehavior={false}
                            className={classNames(
                                tab.current
                                    ? 'border-blue-sidebar text-blue-sidebar snap-center'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 pb-4 px-4 text-sm text-center font-medium cursor-pointer min-w-max lg:min-w-min'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                        >
                            {tab.name}
                        </Link>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Tabs