import React, { useState, cloneElement } from 'react';
import Tabs from '../components/hooks/Tabs';

function IncomesExcomesLayout({ children, user }) {

    const [tabs, setTabs] = useState([
        { id: 1, name: 'Ingresos', current: false, route: '/declaraciones/ingresos' },
        { id: 2, name: 'Egresos', current: false, route: '/declaraciones/egresos' },
        { id: 3, name: 'Egresos en el extranjero', current: false, route: '/declaraciones/egresos-extranjero' },
        { id: 4, name: 'Histórico', current: true, route: '/declaraciones/historico' }
    ]);

    return (
        <>
            <div className='w-full px-0 py-4 lg:p-6 bg-gray-100 min-h-[calc(100dvh-4rem)] pb-12'>
                <Tabs
                    items={tabs}
                    setItems={setTabs}
                />
                <div className='w-full px-4 lg:px-0'>
                    {cloneElement(children, { user })}
                </div>
            </div>
        </>
    )
}

export default IncomesExcomesLayout