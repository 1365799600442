import { initializeApp, getApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { firebaseVapidKey } from "../config";

const firebaseConfig = {
    apiKey: "AIzaSyAQmI_BsbN8TcOkpyd2M0fMc_octu8zdnQ",
    authDomain: "taxpayers-project.firebaseapp.com",
    projectId: "taxpayers-project",
    storageBucket: "taxpayers-project.appspot.com",
    messagingSenderId: "123877756047",
    appId: "1:123877756047:web:decbcf93efb03297d774fa",
    measurementId: "G-GVR3VXYWC9"
};

const createFirebaseApp = () => {
    try {
        return getApp();
    } catch (e) {
        return initializeApp(firebaseConfig);
    }
};

export const getFirebaseToken = async () => {
    try {
        if (await isSupported()) {
            const messaging = getMessaging(createFirebaseApp());
            const fcmToken = getToken(messaging, { vapidKey: firebaseVapidKey });
            return fcmToken;
        } else {
            console.log("Firebase not supported")
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const onFirebaseMessage = async (callback) => {
    if (await isSupported()) {
        const messaging = getMessaging(createFirebaseApp());
        onMessage(messaging, (payload) => {
            callback(payload);
        });
    }
}