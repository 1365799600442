import apiClient from '../apiClient';
import Router from 'next/router';

const translateEmail = email => {
    const formatedAtEmail = email ? email.replace('@', '%40') : '';
    const formatedPlusEmail = formatedAtEmail.replace('+', '%2B');
    return formatedPlusEmail
}

export const login = async (account) => {
    const response = await apiClient.post(`/api/accounts/login/`, account);
    return response.data;
}

export const loginAsTaxpayer = async (data) => {
    const response = await apiClient.post(`/api/accounts/admin-login/`, data);
    return response.data;
}

export const switchAccount = async (accountId) => {
    const response = await apiClient.put(`/api/accounts/switch-account/`, { "account_id": accountId });
    return response.data;
}

export const getAccount = async () => {
    const response = await apiClient.get(`/api/fetch-cache/accounts/taxpayer`);
    return response.data;
}

export const getLead = async () => {
    const response = await apiClient.get(`/api/fetch-cache/leads/taxpayer`);
    return response.data;
}

export const updateUser = async (data) => {
    const response = await apiClient.put('/api/accounts/user', data);
    return response.data;
}

export const updateProfilePhoto = async (data) => {
    const response = await apiClient.put('/api/fetch/accounts/taxpayer/image', data);
    return response;
}

export const verifyPassword = async (data) => {
    const response = await apiClient.post('/api/fetch/sessions/taxpayer/verify-password', data);
    return response;
}

export const requestResetPassword = async (email, type) => {
    const emailFinal = translateEmail(email);
    const response = await apiClient.post(`/api/fetch/sessions/taxpayer/${emailFinal}/reset-password/request`, { type });
    return response.data;
}

export const resetPassword = async (leadId, code, password, type, firstAccess) => {
    const response = await apiClient.post(`/api/fetch/sessions/taxpayer/${leadId}/reset-password`, {
        code,
        password,
        type,
        first_access: firstAccess
    });
    return response.data;
}

export const requestNumberVerification = async (number) => {
    const response = await apiClient.post('/api/fetch/leads/verification-whatsapp', { phone_number: number });
    return response.data;
}

export const verifyNumber = async (code) => {
    const response = await apiClient.post('/api/fetch-cache/leads/verificate-whatsapp', { code });
    return response.data;
}

export const requestEmailVerification = async (email) => {
    const response = await apiClient.post('/api/fetch/leads/verification-email', { email });
    return response.data;
}

export const updatePassword = async (body) => {
    const response = await apiClient.post('/api/fetch/sessions/taxpayer/change-password', body);
    return response.data;
}

export const logout = async (redirect = false) => {
    if (redirect) Router.push('/inicia-sesion');
    await apiClient.post('/api/accounts/logout');
}